import React, { useState } from "react";
import Modal from "react-modal";
import Brand from "./Brand";

Modal.setAppElement("#root");

const About = () => {

  function openResume() {
    window.open("assets/files/Resume_Sue_Nopachinda_Site_Version.pdf");
  }

  return (
    <>
      <div className="tokyo_tm_about">
        <div className="tokyo_tm_title">
          <div className="title_flex">
            <div className="left">
              <h3>About</h3>
            </div>
          </div>
        </div>
        {/* END TITLE */}



        <div className="description margin-bottom-30">
          <div className="description_inner">
            <div className="right">
              <div className="about_image ">
                <img src="assets/img/slider/about_me.jpg" alt="about" />
              </div>
            </div>
            {/* END RIGHT */}
            <div className="left">
              <h3 className="name">Sue Nopachinda, P.E.</h3>
              <p>
                I am a software developer and data analyst living in New York City and Los Angeles county. With a background in civil engineering, my work varies from programming, 3D modeling, and 3D printing. Here are some of my coolest projects:
              </p>
              <ul>
                <li>
                  Traffic engineering analysis platforms which helps reduce vehicle and pedestrian crashes in major cities.                
                </li>
                <li>
                  BIM and VR environments of high-profile facilities for expansion planning.
                </li>
                <li>
                  3D prints of bridges and structures to help engineers convey design intent to clients.
                </li>
              </ul>
              <p>
                My interest in programming started when I used Python to automate paperwork while interning at various civil engineering firms. 
                Little did I know it would shape into a career I love and where I can contribute to public safety!
              </p>
              <div className="tokyo_tm_button">
                <button onClick={openResume} className="ib-button">
                  Get Resume
                </button>
              </div>
              {/* END TOKYO BUTTON */}
            </div>
            {/* END LEFT */}

          </div>
          {/* END DESCRIPTION INNER */}
        </div>

        <div className="about_skills">
          <div className="counter">
            <h3>Skills</h3>
            <ul class="about_skills_list">
              <li>
                <div class="list_inner">
                  <img class="about_skills_svg" src="assets/img/svg/about-coding.svg" alt="coding skills"></img>
                  <h3>Coding</h3>
                  <dl>
                    <dt>JavaScript</dt>
                    <dt>Python</dt>
                    <dt>C#</dt>
                    <dt>C++</dt>
                    <dt>HTML/CSS</dt>
                  </dl>
                </div>
              </li>
              <li>
                <div class="list_inner">
                  <img class="about_skills_svg" src="assets/img/svg/database.svg" alt="backend skills"></img>
                  <h3>Backend</h3>
                  <dl>
                    <dt>Node.js</dt>
                    <dt>Fastify</dt>
                    <dt>REST API</dt>
                    <dt>Postgres & SQL</dt>
                    <dt>AWS Servers</dt>
                  </dl>
                </div>
              </li>
              <li>
                <div class="list_inner">
                  <img class="about_skills_svg" src="assets/img/svg/engineering.svg" alt="backend skills"></img>
                  <h3>Engineering</h3>
                  <dl>
                    <dt>Building Information Modeling</dt>
                    <dt>Autodesk Suite</dt>
                    <dt>ESRI ArcGIS</dt>
                    <dt>LiDAR & Point Clouds</dt>
                    <dt>Virtual Reality</dt>
                  </dl>
                </div>
              </li>
            </ul>

          </div>
          {/* END DESCRIPTION INNER */}
        </div>

        <div className="about_skills">
          <div className="counter">
            <h3>Hobbies</h3>
            <ul class="about_skills_list">
              <li>
                <div class="list_inner">
                  <img class="about_skills_svg" src="assets/img/svg/about-art.svg" alt="coding skills"></img>
                  <h3>Art</h3>
                  <dl>
                    <dt>Photoshop & Clip Studio</dt>
                    <dt>Digital Media</dt>
                    <dt>Copic Markers</dt>
                    <dt>Garage Kit Painting</dt>
                    <dt>Prototyping and Concept Art</dt>
                  </dl>
                </div>
              </li>
              <li>
                <div class="list_inner">
                  <img class="about_skills_svg" src="assets/img/svg/cube.svg" alt="backend skills"></img>
                  <h3>3D Modeling & Printing</h3>
                  <dl>
                    <dt>Parametric Modeling</dt>
                    <dt>Fusion360</dt>
                    <dt>Tinkercad</dt>
                    <dt>Creality CR 3D Printers</dt>
                    <dt>Simplify3D</dt>
                  </dl>
                </div>
              </li>
              <li>
                <div class="list_inner">
                  <img class="about_skills_svg" src="assets/img/svg/documentation.svg" alt="backend skills"></img>
                  <h3>Technical Writing</h3>
                  <dl>
                    <dt>Proposals</dt>
                    <dt>Code Documentation</dt>
                    <dt>User Manuals & Tutorials</dt>
                    <dt>Video & GIF Media</dt>
                    <dt>Custom Graphics and Charts</dt>
                  </dl>
                </div>
              </li>
            </ul>

          </div>
          {/* END DESCRIPTION INNER */}
        </div>
      </div>
    </>
  );
};

export default About;
