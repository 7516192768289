import React from "react";
import Social from "./Social";

const Home = () => {
  function openResume() {
    window.open("assets/files/Resume_Sue_Nopachinda_Site_Version.pdf");
  }

  return (
    <>
      <div className="tokyo_tm_home">
        <div className="home_content">
          <div className="avatar">
            <div
              className="image avatar_img"
              style={{
                backgroundImage: "url(assets/img/slider/profile_image_crop.jpg)",
              }}
            ></div>
            {/* END AVATAR IMAGE */}
          </div>
          {/* END AVATAR */}
          <div className="details">
            <h3 className="name">Sue Nopachinda</h3>
            <p className="job">
              Software developer and data analyst based in New York City and Los Angeles. Technical writing, 3D printing, and electronics enthusiast. Also enjoys drawing and sending postcards.
            </p>
            <div className="home-icons">
              <div className="tokyo_tm_button">
                <button onClick={openResume} className="ib-button">
                  Get Resume
                </button>
              </div>    
              <Social />          
            </div>

            {/* END JOB */}
            {/* <Social /> */}
          </div>
          {/* END DETAILS */}
        </div>
        {/* END HOME CONTENT */}
      </div>
      {/* END HOME */}
    </>
  );
};

export default Home;
