import React, { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";
import ReactTooltip from "react-tooltip";
import ModalVideo from "react-modal-video";
import Modal from "react-modal";
import Social from "./Social";

const Portfolio = () => {
  // for popup video
  const [isOpen, setOpen] = useState(false);
  const [isOpen2, setOpen2] = useState(false);

  // for modal
  const [isOpenCarbotProbe, setisOpenCarbotProbe] = useState(false);
  const [isOpenMikuHalloween, setisOpenMikuHalloween] = useState(false);
  const [isOpenSaberKimono, setisOpenSaberKimono] = useState(false);
  const [isOpenAceWhale, setisOpenAceWhale] = useState(false);
  const [isOpenCarbotZergling, setisOpenCarbotZergling] = useState(false);
  const [isOpenGWB, setisOpenGWB] = useState(false);
  const [isOpenFightstick, setisOpenFightstick] = useState(false);
  const [isOpenLamp, setisOpenLamp] = useState(false);
  
  function toggleModalCarbotProbe() {
    setisOpenCarbotProbe(!isOpenCarbotProbe);
  }
  function toggleModalMikuHalloween() {
    setisOpenMikuHalloween(!isOpenMikuHalloween);
  }
  function toggleModalSaberKimono() {
    setisOpenSaberKimono(!isOpenSaberKimono);
  }
  function toggleModalAceWhale() {
    setisOpenAceWhale(!isOpenAceWhale);
  }
  function toggleModalCarbotZergling() {
    setisOpenCarbotZergling(!isOpenCarbotZergling);
  }
  function toggleModalGWB() {
    setisOpenGWB(!isOpenGWB);
  }
  function toggleModalFightstick() {
    setisOpenFightstick(!isOpenFightstick);
  }
  function toggleModalLamp() {
    setisOpenLamp(!isOpenLamp);
  }
  return (
    <>
      <SimpleReactLightbox>
        <div className="tokyo_tm_portfolio">
          <div className="tokyo_tm_title">
            <div className="title_flex">
              <div className="left">
                <h3>Art & Tinkering</h3>
              </div>
            </div>
          </div>
          {/* END TOKYO_TM_TITLE */}

          <div className="portfolio_filter">
            <Tabs>
              <TabList>
                <Tab>All</Tab>
                <Tab>Postcards</Tab>
                <Tab>3D Printing</Tab>
                <Tab>GK Painting</Tab>
              </TabList>
              {/* END TABLIST */}
              
              <div className="list_wrapper">
                <TabPanel>
                  <ul className="portfolio_list">

                  
                    <SRLWrapper>
                      {/* <------------------- START POSTCARDS -------------------> */}
                      {/* POSTCARD - NAMI*/}
                      <li
                        data-aos="fade-right"
                        data-aos-duration="1200"
                        data-aos-delay="200"
                      >
                        <div className="inner">
                          <div className="entry tokyo_tm_portfolio_animation_wrap">
                            <a href="assets/img/portfolio/nami_postcard.jpg">
                              <img
                                src="assets/img/portfolio/nami_postcard.jpg"
                                alt="Postcard for a friend featuring Nami from League of Legends. Copic Ciao markers on postcard paper."
                                data-tip
                                data-for="nami-postcard"
                              />
                              <ReactTooltip
                                id="nami-postcard"
                                place="bottom"
                                type="light"
                                effect="float"
                                className="tooltip-wrapper"
                              >
                                <div>
                                  <h5>Nami</h5>
                                  <span>Postcards</span>
                                </div>
                              </ReactTooltip>
                            </a>
                          </div>
                        </div>
                      </li>
                      {/* END SHOT */}
                      {/* POSTCARD - NAMI*/}

                      {/* POSTCARD - PINK SUNSHINE */}
                      <li
                        data-aos="fade-right"
                        data-aos-duration="1200"
                        data-aos-delay="200"
                      >
                        <div className="inner">
                          <div className="entry tokyo_tm_portfolio_animation_wrap">
                            <a href="assets/img/portfolio/pink_sunshine_postcard.jpg">
                              <img
                                src="assets/img/portfolio/pink_sunshine_postcard.jpg"
                                alt="Birthday postcard for a friend in Florida. Copic Ciao markers on postcard paper."
                                data-tip
                                data-for="pink-sunshine"
                              />
                              <ReactTooltip
                                id="pink-sunshine"
                                place="bottom"
                                type="light"
                                effect="float"
                                className="tooltip-wrapper"
                              >
                                <div>
                                  <h5>Pink Sunshine</h5>
                                  <span>Postcards</span>
                                </div>
                              </ReactTooltip>
                            </a>
                          </div>
                        </div>
                      </li>
                      {/* END SHOT */}
                      {/* POSTCARD - PINK SUNSHINE */}


                      {/* POSTCARD - CHIAKI*/}
                      <li
                        data-aos="fade-right"
                        data-aos-duration="1200"
                        data-aos-delay="200"
                      >
                        <div className="inner">
                          <div className="entry tokyo_tm_portfolio_animation_wrap">
                            <a href="assets/img/portfolio/chiaki_postcard.jpg">
                              <img
                                src="assets/img/portfolio/chiaki_postcard.jpg"
                                alt="Holiday postcard featuring Chiaki from Danganronpa. Copic Ciao markers on postcard paper."
                                data-tip
                                data-for="chiaki-postcard"
                              />
                              <ReactTooltip
                                id="chiaki-postcard"
                                place="bottom"
                                type="light"
                                effect="float"
                                className="tooltip-wrapper"
                              >
                                <div>
                                  <h5>Chiaki Nanami</h5>
                                  <span>Postcards</span>
                                </div>
                              </ReactTooltip>
                            </a>
                          </div>
                        </div>
                      </li>
                      {/* END SHOT */}
                      {/* POSTCARD - CHIAKI*/}


                      {/* POSTCARD - TOTORO */}
                      <li
                        data-aos="fade-right"
                        data-aos-duration="1200"
                        data-aos-delay="300"
                      >
                        <div className="inner">
                          <div className="entry tokyo_tm_portfolio_animation_wrap">
                            <a href="assets/img/portfolio/totoro_postcard.jpg">
                              <img
                                src="assets/img/portfolio/totoro_postcard.jpg"
                                alt="Holiday postcard featuring Totoro! Copic Ciao markers on postcard paper."
                                data-tip
                                data-for="totoro_postcard"
                              />
                            </a>
                            <ReactTooltip
                              id="totoro_postcard"
                              place="bottom"
                              type="light"
                              effect="float"
                              className="tooltip-wrapper"
                            >
                              <div>
                                <h5>Totoro</h5>
                                <span>Postcards</span>
                              </div>
                            </ReactTooltip>
                          </div>
                        </div>
                      </li>
                      {/* END SHOT */}
                      {/* POSTCARD - TOTORO */}

                      {/* POSTCARD - LUX */}
                      <li
                        data-aos="fade-right"
                        data-aos-duration="1200"
                        data-aos-delay="300"
                      >
                        <div className="inner">
                          <div className="entry tokyo_tm_portfolio_animation_wrap">
                            <a href="assets/img/portfolio/dark_lux.jpg">
                              <img
                                src="assets/img/portfolio/dark_lux.jpg"
                                alt="Birthday postcard featuring Lux from League of Legends! Copic Ciao markers on postcard paper."
                                data-tip
                                data-for="dark_lux"
                              />
                            </a>
                            <ReactTooltip
                              id="dark_lux"
                              place="bottom"
                              type="light"
                              effect="float"
                              className="tooltip-wrapper"
                            >
                              <div>
                                <h5>Lux</h5>
                                <span>Postcards</span>
                              </div>
                            </ReactTooltip>
                          </div>
                        </div>
                      </li>
                      {/* END SHOT */}
                    {/* POSTCARD - LUX */}
                
                    </SRLWrapper>
                    {/* <------------------- END POSTCARDS -------------------> */}

                    {/* <------------------- START 3D PRINTING -------------------> */}
                    {/* CARBOT PROBE */}
                    <li
                      data-aos="fade-right"
                      data-aos-duration="1200"
                      data-aos-delay="100"
                    >
                      <div className="inner">
                        <div className="entry tokyo_tm_portfolio_animation_wrap">
                          <img
                            src="assets/img/portfolio/tinkering/carbot_probe/probe-thumbnail.jpg"
                            alt="Details"
                            data-tip
                            data-for="carbot-probe"
                            onClick={toggleModalCarbotProbe}
                          />

                          <ReactTooltip
                            id="carbot-probe"
                            place="bottom"
                            type="light"
                            effect="float"
                            className="tooltip-wrapper"
                          >
                            <div>
                              <h5>Carbot Probe</h5>
                              <span>3D Printing</span>
                            </div>
                          </ReactTooltip>
                        </div>
                      </div>
                    </li>
                    {/* CARBOT PROBE */}
                    {/* END DETAILS */}

                    {/* ACE WHALE */}
                    <li
                      data-aos="fade-right"
                      data-aos-duration="1200"
                      data-aos-delay="100"
                    >
                      <div className="inner">
                        <div className="entry tokyo_tm_portfolio_animation_wrap">
                          <img
                            src="assets/img/portfolio/tinkering/ace_mentor_whale/whale-thumbnail.png"
                            alt="Details"
                            data-tip
                            data-for="ace-whale"
                            onClick={toggleModalAceWhale}
                          />

                          <ReactTooltip
                            id="ace-whale"
                            place="bottom"
                            type="light"
                            effect="float"
                            className="tooltip-wrapper"
                          >
                            <div>
                              <h5>Ace Mentor Whale</h5>
                              <span>3D Printing</span>
                            </div>
                          </ReactTooltip>
                        </div>
                      </div>
                    </li>
                    {/* ACE WHALE */}
                    {/* END DETAILS */}

                    {/* CARBOT ZERGLING*/}
                    <li
                      data-aos="fade-right"
                      data-aos-duration="1200"
                      data-aos-delay="100"
                    >
                      <div className="inner">
                        <div className="entry tokyo_tm_portfolio_animation_wrap">
                          <img
                            src="assets/img/portfolio/tinkering/carbot_zergling/zergling-thumbnail.jpg"
                            alt="Details"
                            data-tip
                            data-for="carbot-zergling"
                            onClick={toggleModalCarbotZergling}
                          />

                          <ReactTooltip
                            id="carbot-zergling"
                            place="bottom"
                            type="light"
                            effect="float"
                            className="tooltip-wrapper"
                          >
                            <div>
                              <h5>Carbot Zergling</h5>
                              <span>3D Printing</span>
                            </div>
                          </ReactTooltip>
                        </div>
                      </div>
                    </li>
                    {/* CARBOT ZERGLING */}
                    {/* END DETAILS */}

                    {/* GWB*/}
                    <li
                      data-aos="fade-right"
                      data-aos-duration="1200"
                      data-aos-delay="100"
                    >
                      <div className="inner">
                        <div className="entry tokyo_tm_portfolio_animation_wrap">
                          <img
                            src="assets/img/portfolio/tinkering/gwb/GWB_thumbnail.jpg"
                            alt="Details"
                            data-tip
                            data-for="gwb"
                            onClick={toggleModalGWB}
                          />

                          <ReactTooltip
                            id="gwb"
                            place="bottom"
                            type="light"
                            effect="float"
                            className="tooltip-wrapper"
                          >
                            <div>
                              <h5>George Washington Bridge</h5>
                              <span>3D Printing</span>
                            </div>
                          </ReactTooltip>
                        </div>
                      </div>
                    </li>
                    {/* GWB */}
                    {/* END DETAILS */}

                    {/* Fightstick*/}
                    <li
                      data-aos="fade-right"
                      data-aos-duration="1200"
                      data-aos-delay="100"
                    >
                      <div className="inner">
                        <div className="entry tokyo_tm_portfolio_animation_wrap">
                          <img
                            src="assets/img/portfolio/tinkering/fightstick/top-view-crop.jpg"
                            alt="Details"
                            data-tip
                            data-for="fightstick"
                            onClick={toggleModalFightstick}
                          />

                          <ReactTooltip
                            id="fightstick"
                            place="bottom"
                            type="light"
                            effect="float"
                            className="tooltip-wrapper"
                          >
                            <div>
                              <h5>SMT V Fightstick</h5>
                              <span>3D Printing</span>
                            </div>
                          </ReactTooltip>
                        </div>
                      </div>
                    </li>
                    {/* Fightstick */}
                    {/* END DETAILS */}


                    {/* Lamp*/}
                    <li
                      data-aos="fade-right"
                      data-aos-duration="1200"
                      data-aos-delay="100"
                    >
                      <div className="inner">
                        <div className="entry tokyo_tm_portfolio_animation_wrap">
                          <img
                            src="assets/img/portfolio/tinkering/final_fantasy_lamp/lamp-thumbnail.jpg"
                            alt="Details"
                            data-tip
                            data-for="lamp"
                            onClick={toggleModalLamp}
                          />

                          <ReactTooltip
                            id="lamp"
                            place="bottom"
                            type="light"
                            effect="float"
                            className="tooltip-wrapper"
                          >
                            <div>
                              <h5>Aethernet Shard Lamp (WIP)</h5>
                              <span>3D Printing</span>
                            </div>
                          </ReactTooltip>
                        </div>
                      </div>
                    </li>
                    {/* Lamp */}
                    {/* END DETAILS */}

                    {/* <------------------- END 3D PRINTING -------------------> */}
                    

                    
                    {/* <------------------- START GK PAINTING -------------------> */}
                    {/* Miku Halloween */}
                    <li
                      data-aos="fade-right"
                      data-aos-duration="1200"
                      data-aos-delay="100"
                    >
                      <div className="inner">
                        <div className="entry tokyo_tm_portfolio_animation_wrap">
                          <img
                            src="assets/img/portfolio/gk_painting/miku_halloween/miku.jpg"
                            alt="Details"
                            data-tip
                            data-for="miku-halloween"
                            onClick={toggleModalMikuHalloween}
                          />

                          <ReactTooltip
                            id="miku-halloween"
                            place="bottom"
                            type="light"
                            effect="float"
                            className="tooltip-wrapper"
                          >
                            <div>
                              <h5>Trick or Miku</h5>
                              <span>GK Painting</span>
                            </div>
                          </ReactTooltip>
                        </div>
                      </div>
                    </li>
                    {/* Miku Halloween */}
                    {/* END DETAILS */}


                    {/* SABER KIMONO */}
                    <li
                      data-aos="fade-right"
                      data-aos-duration="1200"
                      data-aos-delay="100"
                    >
                      <div className="inner">
                        <div className="entry tokyo_tm_portfolio_animation_wrap">
                          <img
                            src="assets/img/portfolio/gk_painting/saber_kimono/saber-thumbnail.png"
                            alt="Details"
                            data-tip
                            data-for="saber-kimono"
                            onClick={toggleModalSaberKimono}
                          />

                          <ReactTooltip
                            id="saber-kimono"
                            place="bottom"
                            type="light"
                            effect="float"
                            className="tooltip-wrapper"
                          >
                            <div>
                              <h5>Saber Kimono Version</h5>
                              <span>GK Painting</span>
                            </div>
                          </ReactTooltip>
                        </div>
                      </div>
                    </li>
                    {/* SABER KIMONO */}
                    {/* END DETAILS */}

                    {/* <------------------- END GK PAINTING -------------------> */}                    

                  </ul>
                  {/* END PORTFOLIO LIST */}
                </TabPanel>
                {/* END ALL PORTFOLIO GALLERY */}


                {/* <------- TAB PANEL POSTCARDS ------->  */}

                <TabPanel>
                  <ul className="portfolio_list">
                    <SRLWrapper>
                      {/* <------------------- START POSTCARDS -------------------> */}
                      {/* POSTCARD - NAMI*/}
                      <li
                        data-aos="fade-right"
                        data-aos-duration="1200"
                        data-aos-delay="200"
                      >
                        <div className="inner">
                          <div className="entry tokyo_tm_portfolio_animation_wrap">
                            <a href="assets/img/portfolio/nami_postcard.jpg">
                              <img
                                src="assets/img/portfolio/nami_postcard.jpg"
                                alt="Postcard for a friend featuring Nami from League of Legends. Copic Ciao markers on postcard paper."
                                data-tip
                                data-for="nami-postcard"
                              />
                              <ReactTooltip
                                id="nami-postcard"
                                place="bottom"
                                type="light"
                                effect="float"
                                className="tooltip-wrapper"
                              >
                                <div>
                                  <h5>Nami</h5>
                                  <span>Postcards</span>
                                </div>
                              </ReactTooltip>
                            </a>
                          </div>
                        </div>
                      </li>
                      {/* END SHOT */}
                      {/* POSTCARD - NAMI*/}

                      {/* POSTCARD - PINK SUNSHINE */}
                      <li
                        data-aos="fade-right"
                        data-aos-duration="1200"
                        data-aos-delay="200"
                      >
                        <div className="inner">
                          <div className="entry tokyo_tm_portfolio_animation_wrap">
                            <a href="assets/img/portfolio/pink_sunshine_postcard.jpg">
                              <img
                                src="assets/img/portfolio/pink_sunshine_postcard.jpg"
                                alt="Birthday postcard for a friend in Florida. Copic Ciao markers on postcard paper."
                                data-tip
                                data-for="pink-sunshine"
                              />
                              <ReactTooltip
                                id="pink-sunshine"
                                place="bottom"
                                type="light"
                                effect="float"
                                className="tooltip-wrapper"
                              >
                                <div>
                                  <h5>Pink Sunshine</h5>
                                  <span>Postcards</span>
                                </div>
                              </ReactTooltip>
                            </a>
                          </div>
                        </div>
                      </li>
                      {/* END SHOT */}
                      {/* POSTCARD - PINK SUNSHINE */}


                      {/* POSTCARD - CHIAKI*/}
                      <li
                        data-aos="fade-right"
                        data-aos-duration="1200"
                        data-aos-delay="200"
                      >
                        <div className="inner">
                          <div className="entry tokyo_tm_portfolio_animation_wrap">
                            <a href="assets/img/portfolio/chiaki_postcard.jpg">
                              <img
                                src="assets/img/portfolio/chiaki_postcard.jpg"
                                alt="Holiday postcard featuring Chiaki from Danganronpa. Copic Ciao markers on postcard paper."
                                data-tip
                                data-for="chiaki-postcard"
                              />
                              <ReactTooltip
                                id="chiaki-postcard"
                                place="bottom"
                                type="light"
                                effect="float"
                                className="tooltip-wrapper"
                              >
                                <div>
                                  <h5>Chiaki Nanami</h5>
                                  <span>Postcards</span>
                                </div>
                              </ReactTooltip>
                            </a>
                          </div>
                        </div>
                      </li>
                      {/* END SHOT */}
                      {/* POSTCARD - CHIAKI*/}


                      {/* POSTCARD - TOTORO */}
                      <li
                        data-aos="fade-right"
                        data-aos-duration="1200"
                        data-aos-delay="300"
                      >
                        <div className="inner">
                          <div className="entry tokyo_tm_portfolio_animation_wrap">
                            <a href="assets/img/portfolio/totoro_postcard.jpg">
                              <img
                                src="assets/img/portfolio/totoro_postcard.jpg"
                                alt="Holiday postcard featuring Totoro! Copic Ciao markers on postcard paper."
                                data-tip
                                data-for="totoro_postcard"
                              />
                            </a>
                            <ReactTooltip
                              id="totoro_postcard"
                              place="bottom"
                              type="light"
                              effect="float"
                              className="tooltip-wrapper"
                            >
                              <div>
                                <h5>Totoro</h5>
                                <span>Postcards</span>
                              </div>
                            </ReactTooltip>
                          </div>
                        </div>
                      </li>
                      {/* END SHOT */}
                      {/* POSTCARD - TOTORO */}

                      {/* POSTCARD - LUX */}
                      <li
                        data-aos="fade-right"
                        data-aos-duration="1200"
                        data-aos-delay="300"
                      >
                        <div className="inner">
                          <div className="entry tokyo_tm_portfolio_animation_wrap">
                            <a href="assets/img/portfolio/dark_lux.jpg">
                              <img
                                src="assets/img/portfolio/dark_lux.jpg"
                                alt="Birthday postcard featuring Lux from League of Legends! Copic Ciao markers on postcard paper."
                                data-tip
                                data-for="dark_lux"
                              />
                            </a>
                            <ReactTooltip
                              id="dark_lux"
                              place="bottom"
                              type="light"
                              effect="float"
                              className="tooltip-wrapper"
                            >
                              <div>
                                <h5>Lux</h5>
                                <span>Postcards</span>
                              </div>
                            </ReactTooltip>
                          </div>
                        </div>
                      </li>
                      {/* END SHOT */}
                    {/* POSTCARD - LUX */}
                    </SRLWrapper>
                  </ul>
                  {/* END PORTFOLIO LIST */}
                </TabPanel>
                {/* END PHOTOGRAHY */}



                {/* <------- TAB PANEL 3D PRINTING ------->  */}
                <TabPanel>
                  <ul className="portfolio_list">
                    {/* <------------------- START 3D PRINTING -------------------> */}
                    {/* CARBOT PROBE */}
                    <li
                      data-aos="fade-right"
                      data-aos-duration="1200"
                      data-aos-delay="100"
                    >
                      <div className="inner">
                        <div className="entry tokyo_tm_portfolio_animation_wrap">
                          <img
                            src="assets/img/portfolio/tinkering/carbot_probe/probe-thumbnail.jpg"
                            alt="Details"
                            data-tip
                            data-for="carbot-probe"
                            onClick={toggleModalCarbotProbe}
                          />

                          <ReactTooltip
                            id="carbot-probe"
                            place="bottom"
                            type="light"
                            effect="float"
                            className="tooltip-wrapper"
                          >
                            <div>
                              <h5>Carbot Probe</h5>
                              <span>3D Printing</span>
                            </div>
                          </ReactTooltip>
                        </div>
                      </div>
                    </li>
                    {/* CARBOT PROBE */}
                    {/* END DETAILS */}

                    {/* ACE WHALE */}
                    <li
                      data-aos="fade-right"
                      data-aos-duration="1200"
                      data-aos-delay="100"
                    >
                      <div className="inner">
                        <div className="entry tokyo_tm_portfolio_animation_wrap">
                          <img
                            src="assets/img/portfolio/tinkering/ace_mentor_whale/whale-thumbnail.png"
                            alt="Details"
                            data-tip
                            data-for="ace-whale"
                            onClick={toggleModalAceWhale}
                          />

                          <ReactTooltip
                            id="ace-whale"
                            place="bottom"
                            type="light"
                            effect="float"
                            className="tooltip-wrapper"
                          >
                            <div>
                              <h5>Ace Mentor Whale</h5>
                              <span>3D Printing</span>
                            </div>
                          </ReactTooltip>
                        </div>
                      </div>
                    </li>
                    {/* ACE WHALE */}
                    {/* END DETAILS */}

                    {/* CARBOT ZERGLING*/}
                    <li
                      data-aos="fade-right"
                      data-aos-duration="1200"
                      data-aos-delay="100"
                    >
                      <div className="inner">
                        <div className="entry tokyo_tm_portfolio_animation_wrap">
                          <img
                            src="assets/img/portfolio/tinkering/carbot_zergling/zergling-thumbnail.jpg"
                            alt="Details"
                            data-tip
                            data-for="carbot-zergling"
                            onClick={toggleModalCarbotZergling}
                          />

                          <ReactTooltip
                            id="carbot-zergling"
                            place="bottom"
                            type="light"
                            effect="float"
                            className="tooltip-wrapper"
                          >
                            <div>
                              <h5>Carbot Zergling</h5>
                              <span>3D Printing</span>
                            </div>
                          </ReactTooltip>
                        </div>
                      </div>
                    </li>
                    {/* CARBOT ZERGLING */}
                    {/* END DETAILS */}

                    {/* GWB*/}
                    <li
                      data-aos="fade-right"
                      data-aos-duration="1200"
                      data-aos-delay="100"
                    >
                      <div className="inner">
                        <div className="entry tokyo_tm_portfolio_animation_wrap">
                          <img
                            src="assets/img/portfolio/tinkering/gwb/GWB_thumbnail.jpg"
                            alt="Details"
                            data-tip
                            data-for="gwb"
                            onClick={toggleModalGWB}
                          />

                          <ReactTooltip
                            id="gwb"
                            place="bottom"
                            type="light"
                            effect="float"
                            className="tooltip-wrapper"
                          >
                            <div>
                              <h5>George Washington Bridge</h5>
                              <span>3D Printing</span>
                            </div>
                          </ReactTooltip>
                        </div>
                      </div>
                    </li>
                    {/* GWB */}
                    {/* END DETAILS */}

                    {/* Fightstick*/}
                    <li
                      data-aos="fade-right"
                      data-aos-duration="1200"
                      data-aos-delay="100"
                    >
                      <div className="inner">
                        <div className="entry tokyo_tm_portfolio_animation_wrap">
                          <img
                            src="assets/img/portfolio/tinkering/fightstick/top-view-crop.jpg"
                            alt="Details"
                            data-tip
                            data-for="fightstick"
                            onClick={toggleModalFightstick}
                          />

                          <ReactTooltip
                            id="fightstick"
                            place="bottom"
                            type="light"
                            effect="float"
                            className="tooltip-wrapper"
                          >
                            <div>
                              <h5>SMT V Fightstick</h5>
                              <span>3D Printing</span>
                            </div>
                          </ReactTooltip>
                        </div>
                      </div>
                    </li>
                    {/* Fightstick */}
                    {/* END DETAILS */}


                    {/* Lamp*/}
                    <li
                      data-aos="fade-right"
                      data-aos-duration="1200"
                      data-aos-delay="100"
                    >
                      <div className="inner">
                        <div className="entry tokyo_tm_portfolio_animation_wrap">
                          <img
                            src="assets/img/portfolio/tinkering/final_fantasy_lamp/lamp-thumbnail.jpg"
                            alt="Details"
                            data-tip
                            data-for="lamp"
                            onClick={toggleModalLamp}
                          />

                          <ReactTooltip
                            id="lamp"
                            place="bottom"
                            type="light"
                            effect="float"
                            className="tooltip-wrapper"
                          >
                            <div>
                              <h5>Aethernet Shard Lamp (WIP)</h5>
                              <span>3D Printing</span>
                            </div>
                          </ReactTooltip>
                        </div>
                      </div>
                    </li>
                    {/* Lamp */}
                    {/* END DETAILS */}

                    {/* <------------------- END 3D PRINTING -------------------> */}

                  </ul>
                  {/* END DETAILS GALLERY */}
                </TabPanel>




                {/* <------- TAB PANEL GK PAINTING------->  */}
                <TabPanel>
                  <ul className="portfolio_list">
                    {/* Miku Halloween */}
                    <li
                      data-aos="fade-right"
                      data-aos-duration="1200"
                      data-aos-delay="100"
                    >
                      <div className="inner">
                        <div className="entry tokyo_tm_portfolio_animation_wrap">
                          <img
                            src="assets/img/portfolio/gk_painting/miku_halloween/miku.jpg"
                            alt="Details"
                            data-tip
                            data-for="miku-halloween"
                            onClick={toggleModalMikuHalloween}
                          />

                          <ReactTooltip
                            id="miku-halloween"
                            place="bottom"
                            type="light"
                            effect="float"
                            className="tooltip-wrapper"
                          >
                            <div>
                              <h5>Trick or Miku</h5>
                              <span>GK Painting</span>
                            </div>
                          </ReactTooltip>
                        </div>
                      </div>
                    </li>
                    {/* Miku Halloween */}
                    {/* END DETAILS */}


                    {/* SABER KIMONO */}
                    <li
                      data-aos="fade-right"
                      data-aos-duration="1200"
                      data-aos-delay="100"
                    >
                      <div className="inner">
                        <div className="entry tokyo_tm_portfolio_animation_wrap">
                          <img
                            src="assets/img/portfolio/gk_painting/saber_kimono/saber-thumbnail.png"
                            alt="Details"
                            data-tip
                            data-for="saber-kimono"
                            onClick={toggleModalSaberKimono}
                          />

                          <ReactTooltip
                            id="saber-kimono"
                            place="bottom"
                            type="light"
                            effect="float"
                            className="tooltip-wrapper"
                          >
                            <div>
                              <h5>Saber Kimono Version</h5>
                              <span>GK Painting</span>
                            </div>
                          </ReactTooltip>
                        </div>
                      </div>
                    </li>
                    {/* SABER KIMONO */}
                    {/* END DETAILS */}

                  </ul>
                  {/* END DETAILS GALLERY */}
                </TabPanel>
                {/* END CREATIVE PORTFOLIO GALLERY */}

              </div>
              {/* END LIST WRAPPER */}
            </Tabs>
          </div>
        </div>
      </SimpleReactLightbox>

{/* <------------------ MODAL - 3D PRINT ------------------> */}

      {/* <----------- CARBOT PROBE DETAILS --------------> */}
      {/* START MODAL FOR PORTFOLIO DETAILS */}
      <Modal
        isOpen={isOpenCarbotProbe}
        onRequestClose={toggleModalCarbotProbe}
        contentLabel="My dialog"
        className="mymodal"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
      >
        <div className="tokyo_tm_modalbox_news portfolio_tm_modalbox">
          <button className="close-modal" onClick={toggleModalCarbotProbe}>
            <img src="assets/img/svg/cancel.svg" alt="close icon" />
          </button>
          {/* END CLOSE ICON */}
          <div className="box_inner">
            <div className="description_wrap scrollable">
              <div className="image">
                <img src="assets/img/thumbs/4-3.jpg" alt="tumb" />
                <div
                  className="main"
                  style={{
                    backgroundImage: "url(assets/img/portfolio/tinkering/carbot_probe/probe-splash.jpg)",
                  }}
                ></div>
              </div>
              {/* END IMAGE */}
              <div className="portfolio_main_title">
                <h3>Carbot Probe</h3>
                <span>3D Printing</span>
              </div>
              {/* END portfolio_main_title */}
              <div className="main_details">
                <div className="textbox">
                  <p>
                    This was my first major project involving 3D printing and electronics. I had the following design goals:
                    <ol className="ordered-list-padding">
                      <li>The probes contained a rechargable LED which could be turned on or off.</li>
                      <li>The thumbs can rotate and be posable.</li>
                      <li>The probe's thumbs can hold vespene gas and mineral accessories.</li>
                    </ol>
                  </p>
                  <p>
                    To learn how to create a cricuit for the LED and charger, I used tutorials from Adafruit and a DIY electronics book. 
                    I practiced soldering and heat sink tubing before implementing the production circuit. For the posable thumbs, I studied 
                    how joints moved in old action figures and designed a joint washer. The accessories and thumbs have magnets embedded so the probes
                    can pose and hold all accessories.
                  </p>
                  <p>
                    This project was an incredible learning experience and boosted my confidence tackling future projects. These probes also recieved a lot of love 
                    on social media from the Starcraft community. They have been sent around the world including Canada, South Africa, and Europe.
                  </p>
                </div>
                <div className="detailbox">
                  <ul>
                    <li>
                      <span className="first">Modeling Software</span>
                      <span>Fusion 360</span>
                    </li>
                    <li>
                      <span className="first">3D Printer</span>
                      <span>Creality CR-10</span>
                    </li>
                    <li>
                      <span className="first">Series</span>
                      <span>StarCrafts by Carbot Productions</span>
                    </li>
                    <li>
                      <span className="first">Features</span>
                      <span>Rechargable LEDs, posable thumbs, magnetic accessories</span>
                    </li>
                  </ul>
                </div>
              </div>
              {/* main_details */}

              <div className="additional_images">
                <ul className="gallery_zoom">
                  <li>
                    <div className="list_inner">
                      <div className="my_image">
                        <img src="img/thumbs/4-2.jpg" alt="thumb" />
                        <div
                          className="main"
                          style={{
                            backgroundImage: "url(assets/img/portfolio/tinkering/carbot_probe/probe-minerals-crop.jpg)",
                          }}
                        ></div>
                      </div>
                    </div>
                  </li>
                  {/* END SHOT */}
                  <li>
                    <div className="list_inner">
                      <div className="my_image">
                        <img src="img/thumbs/4-2.jpg" alt="thumb" />
                        <div
                          className="main"
                          style={{
                            backgroundImage: "url(assets/img/portfolio/tinkering/carbot_probe/probe-light-gas.jpg)",
                          }}
                        ></div>
                      </div>
                    </div>
                  </li>
                  {/* END SHOT */}
                  <li>
                    <div className="list_inner">
                      <div className="my_image">
                        <img src="img/thumbs/4-2.jpg" alt="thumb" />
                        <div
                          className="main"
                          style={{
                            backgroundImage: "url(assets/img/portfolio/tinkering/carbot_probe/probe-concept.png)",
                          }}
                        ></div>
                      </div>
                    </div>
                  </li>
                  {/* END SHOT */}
                  <li>
                    <div className="list_inner">
                      <div className="my_image">
                        <img src="img/thumbs/4-2.jpg" alt="thumb" />
                        <div
                          className="main"
                          style={{
                            backgroundImage: "url(assets/img/portfolio/tinkering/carbot_probe/probe-light-crop.jpg)",
                          }}
                        ></div>
                      </div>
                    </div>
                  </li>
                  {/* END SHOT */}
                </ul>
              </div>
            </div>
          </div>
          {/* END BOX INNER */}
        </div>
        {/* END MODALBOX NEWS */}
      </Modal>
      {/* END MODAL FOR PORTFOLIO DETAILS */}
      {/* <----------- CARBOT PROBE DETAILS --------------> */}


      {/* <----------- ACE WHALE DETAILS --------------> */}
      {/* START MODAL FOR PORTFOLIO DETAILS */}
      <Modal
        isOpen={isOpenAceWhale}
        onRequestClose={toggleModalAceWhale}
        contentLabel="My dialog"
        className="mymodal"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
      >
        <div className="tokyo_tm_modalbox_news portfolio_tm_modalbox">
          <button className="close-modal" onClick={toggleModalAceWhale}>
            <img src="assets/img/svg/cancel.svg" alt="close icon" />
          </button>
          {/* END CLOSE ICON */}
          <div className="box_inner">
            <div className="description_wrap scrollable">
              <div className="image">
                <div
                  className="main"
                  style={{
                    backgroundImage: "url(assets/img/portfolio/tinkering/ace_mentor_whale/whale-splash.png",
                  }}
                ></div>
              </div>
              {/* END IMAGE */}
              <div className="portfolio_main_title">
                <h3>Ace Mentor Whale</h3>
                <span>3D Printing</span>
              </div>
              {/* END portfolio_main_title */}
              <div className="main_details">
                <div className="textbox">
                  <p>
                    The ACE Mentor program is a high school program that teaches students the fundamentals of Architecture, Construction, and Engineering. Industry professionals
                    volunteer as mentors as the students design a building throughout the year-long program.
                  </p>
                  <p>
                    I was part of the program when I was in high school and the experience
                    had a profound effect as I entered college for civil engineering. After graduating, I've been a mentor for over three years teaching engineering-related technology.
                  </p>
                  <p>
                    The 2020 team wanted to design a building with whale-inspired aesthetics. As part of my presentation about engineering technology,
                    I modeled and 3D printed this whale for all the students and mentors. Everyone was super excited to take their whale home! I hope I made a small influence
                    on the students to pursue engineering or their dreams!
                  </p>
                </div>
                <div className="detailbox">
                  <ul>
                    <li>
                      <span className="first">Modeling Software</span>
                      <span>Tinkercad</span>
                    </li>
                    <li>
                      <span className="first">3D Printer</span>
                      <span>Creality CR-10</span>
                    </li>
                    <li>
                      <span className="first">Series</span>
                      <span>Personal Project</span>
                    </li>
                    <li>
                      <span className="first">Features</span>
                      <span>Cute blush marks and smile</span>
                    </li>
                  </ul>
                </div>
              </div>
              {/* main_details */}

              <div className="additional_images">
                <ul className="gallery_zoom">
                  <li className="empty_image_row "></li>

                  {/* END SHOT */}
                  <li>
                    <div className="list_inner">
                      <div className="my_image">
                        <img src="img/thumbs/4-2.jpg" alt="thumb" />
                        <div
                          className="main"
                          style={{
                            backgroundImage: "url(assets/img/portfolio/tinkering/ace_mentor_whale/print-2.png)",
                          }}
                        ></div>
                      </div>
                    </div>
                  </li>
                  {/* END SHOT */}
                  <li>
                    <div className="list_inner">
                      <div className="my_image">
                        <img src="img/thumbs/4-2.jpg" alt="thumb" />
                        <div
                          className="main"
                          style={{
                            backgroundImage: "url(assets/img/portfolio/tinkering/ace_mentor_whale/print-1.png)",
                          }}
                        ></div>
                      </div>
                    </div>
                  </li>
                  {/* END SHOT */}
                  <li className="empty_image_row "></li>

                  <li>
                    <div className="list_inner">
                      <div className="my_image">
                        <img src="img/thumbs/4-2.jpg" alt="thumb" />
                        <div
                          className="main"
                          style={{
                            backgroundImage: "url(assets/img/portfolio/tinkering/ace_mentor_whale/whale-front-crop.png)",
                          }}
                        ></div>
                      </div>
                    </div>
                  </li>
                  {/* END SHOT */}
                  <li>
                    <div className="list_inner">
                      <div className="my_image">
                        <img src="img/thumbs/4-2.jpg" alt="thumb" />
                        <div
                          className="main"
                          style={{
                            backgroundImage: "url(assets/img/portfolio/tinkering/ace_mentor_whale/whale-side-crop.png)",
                          }}
                        ></div>
                      </div>
                    </div>
                  </li>
                  {/* END SHOT */}
                  <li>
                    <div className="list_inner">
                      <div className="my_image">
                        <img src="img/thumbs/4-2.jpg" alt="thumb" />
                        <div
                          className="main"
                          style={{
                            backgroundImage: "url(assets/img/portfolio/tinkering/ace_mentor_whale/whale-back-crop.png",
                          }}
                        ></div>
                      </div>
                    </div>
                  </li>
                  {/* END SHOT */}
                </ul>
              </div>
            </div>
          </div>
          {/* END BOX INNER */}
        </div>
        {/* END MODALBOX NEWS */}
      </Modal>
      {/* END MODAL FOR PORTFOLIO DETAILS */}
      {/* <----------- ACE WHALE DETAILS --------------> */}


      {/* <----------- Carbot Zergling DETAILS --------------> */}
      {/* START MODAL FOR PORTFOLIO DETAILS */}
      <Modal
        isOpen={isOpenCarbotZergling}
        onRequestClose={toggleModalCarbotZergling}
        contentLabel="My dialog"
        className="mymodal"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
      >
        <div className="tokyo_tm_modalbox_news portfolio_tm_modalbox">
          <button className="close-modal" onClick={toggleModalCarbotZergling}>
            <img src="assets/img/svg/cancel.svg" alt="close icon" />
          </button>
          {/* END CLOSE ICON */}
          <div className="box_inner">
            <div className="description_wrap scrollable">
              <div className="image">
                <div
                  className="main"
                  style={{
                    backgroundImage: "url(assets/img/portfolio/tinkering/carbot_zergling/zergling-splash-crop.png",
                  }}
                ></div>
              </div>
              {/* END IMAGE */}
              <div className="portfolio_main_title">
                <h3>Carbot Zergling</h3>
                <span>3D Printing</span>
              </div>
              {/* END portfolio_main_title */}
              <div className="main_details">
                <div className="textbox">
                  <p>
                    This was my first model using Blender! The process was challenging as Blender modeling is very different from parametric modeling. 
                    I drew a front and side view as a reference while following Youtube tutorials on Blender modeling. I also modeled and printed a removable
                    tophat and mustache for additional cuteness.
                  </p>
                  <p>
                    The zergling's home is a takeout box with other Carbot characters drawn on the sides. The box is filled with origami stars made in part by my friends
                    who generously donated their break time. 
                  </p>
                </div>
                <div className="detailbox">
                  <ul>
                    <li>
                      <span className="first">Modeling Software</span>
                      <span>Blender</span>
                    </li>
                    <li>
                      <span className="first">3D Printer</span>
                      <span>Creality CR-10</span>
                    </li>
                    <li>
                      <span className="first">Series</span>
                      <span>Starcrafts by Carbot Productions</span>
                    </li>
                    <li>
                      <span className="first">Features</span>
                      <span>Removable top hat and mustache</span>
                    </li>
                  </ul>
                </div>
              </div>
              {/* main_details */}
              <div className="additional_images_miku">
                <ul className="gallery_zoom">
                  <li>
                    <div className="list_inner">
                      <div className="miku-halloween">
                        <div
                          className="main"
                          style={{
                            backgroundImage: "url(assets/img/portfolio/tinkering/carbot_zergling/zergling-1.jpg)",
                          }}
                        ></div>
                      </div>
                    </div>
                  </li>
                  {/* END SHOT */}
                  <li>
                    <div className="list_inner">
                      <div className="miku-halloween">
                        <div
                          className="main"
                          style={{
                            backgroundImage: "url(assets/img/portfolio/tinkering/carbot_zergling/zergling-2.jpg)",
                          }}
                        ></div>
                      </div>
                    </div>
                  </li>
                  {/* END SHOT */}
                  <li>
                    <div className="list_inner">
                      <div className="miku-halloween">
                        <div
                          className="main"
                          style={{
                            backgroundImage: "url(assets/img/portfolio/tinkering/carbot_zergling/zergling-3.jpg)",
                          }}
                        ></div>
                      </div>
                    </div>
                  </li>
                  {/* END SHOT */}
                  <li>
                    <div className="list_inner">
                      <div className="miku-halloween">
                        <div
                          className="main"
                          style={{
                            backgroundImage: "url(assets/img/portfolio/tinkering/carbot_zergling/zergling-4.jpg)",
                          }}
                        ></div>
                      </div>
                    </div>
                  </li>
                  {/* END SHOT */}
                  <li>
                    <div className="list_inner">
                      <div className="miku-halloween">
                        <div
                          className="main"
                          style={{
                            backgroundImage: "url(assets/img/portfolio/tinkering/carbot_zergling/zergling-5.jpg)",
                          }}
                        ></div>
                      </div>
                    </div>
                  </li>
                  {/* END SHOT */}
                  <li>
                    <div className="list_inner">
                      <div className="miku-halloween">
                        <div
                          className="main"
                          style={{
                            backgroundImage: "url(assets/img/portfolio/tinkering/carbot_zergling/zergling-6.jpg)",
                          }}
                        ></div>
                      </div>
                    </div>
                  </li>
                  {/* END SHOT */}
                  <li>
                    <div className="list_inner">
                      <div className="miku-halloween">
                        <div
                          className="main"
                          style={{
                            backgroundImage: "url(assets/img/portfolio/tinkering/carbot_zergling/zergling-7.jpg)",
                          }}
                        ></div>
                      </div>
                    </div>
                  </li>
                  {/* END SHOT */}
                  <li>
                    <div className="list_inner">
                      <div className="miku-halloween">
                        <div
                          className="main"
                          style={{
                            backgroundImage: "url(assets/img/portfolio/tinkering/carbot_zergling/zergling-8.jpg)",
                          }}
                        ></div>
                      </div>
                    </div>
                  </li>
                  {/* END SHOT */}
                </ul>
              </div>
            </div>
          </div>
          {/* END BOX INNER */}
        </div>
        {/* END MODALBOX NEWS */}
      </Modal>
      {/* END MODAL FOR PORTFOLIO DETAILS */}
      {/* <----------- Carbot Zergling DETAILS --------------> */}


      {/* <----------- GWB DETAILS --------------> */}
      {/* START MODAL FOR PORTFOLIO DETAILS */}
      <Modal
        isOpen={isOpenGWB}
        onRequestClose={toggleModalGWB}
        contentLabel="My dialog"
        className="mymodal"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
      >
        <div className="tokyo_tm_modalbox_news portfolio_tm_modalbox">
          <button className="close-modal" onClick={toggleModalGWB}>
            <img src="assets/img/svg/cancel.svg" alt="close icon" />
          </button>
          {/* END CLOSE ICON */}
          <div className="box_inner">
            <div className="description_wrap scrollable">
              <div className="image">
                <div
                  className="main"
                  style={{
                    backgroundImage: "url(assets/img/portfolio/tinkering/gwb/gwb_splash.png",
                  }}
                ></div>
              </div>
              {/* END IMAGE */}
              <div className="portfolio_main_title">
                <h3>George Washington Bridge</h3>
                <span>3D Printing</span>
              </div>
              {/* END portfolio_main_title */}
              <div className="main_details">
                <div className="textbox">
                  <p>
                    This model served as a proof of concept for 3D printing structural and architectural models as a possible service. 
                    Clients can ensure design intent was being met by reviewing a scaled 3D model with 2D plan drawings. 
                  </p>
                  <p>
                  The top roadway deck features a pedestrian walkway that travels through the bridge's tower truss system. The structural system supporting the double-decker is modular;
                  the beams are designed as clips with both ends snapping into the horizontal sides of the deck.
                  </p>
                </div>
                <div className="detailbox">
                  <ul>
                    <li>
                      <span className="first">Modeling Software</span>
                      <span>Sketchup</span>
                    </li>
                    <li>
                      <span className="first">3D Printer</span>
                      <span>Creality CR-10S</span>
                    </li>
                    <li>
                      <span className="first">Series</span>
                      <span>Personal project</span>
                    </li>
                    <li>
                      <span className="first">Features</span>
                      <span>Modular truss design, double decker bridge, pedestrian walkway details</span>
                    </li>
                  </ul>
                </div>
              </div>
              {/* main_details */}
              <div className="additional_images_miku">
                <ul className="gallery_zoom">
                  <li>
                    <div className="list_inner">
                      <div className="miku-halloween">
                        <div
                          className="main"
                          style={{
                            backgroundImage: "url(assets/img/portfolio/tinkering/gwb/bridge-3.jpg)",
                          }}
                        ></div>
                      </div>
                    </div>
                  </li>
                  {/* END SHOT */}
                  <li>
                    <div className="list_inner">
                      <div className="miku-halloween">
                        <div
                          className="main"
                          style={{
                            backgroundImage: "url(assets/img/portfolio/tinkering/gwb/bridge-2.jpg)",
                          }}
                        ></div>
                      </div>
                    </div>
                  </li>
                  {/* END SHOT */}
                  <li>
                    <div className="list_inner">
                      <div className="miku-halloween">
                        <div
                          className="main"
                          style={{
                            backgroundImage: "url(assets/img/portfolio/tinkering/gwb/bridge-1.jpg)",
                          }}
                        ></div>
                      </div>
                    </div>
                  </li>
                  {/* END SHOT */}
                </ul>
              </div>
            </div>
          </div>
          {/* END BOX INNER */}
        </div>
        {/* END MODALBOX NEWS */}
      </Modal>
      {/* END MODAL FOR PORTFOLIO DETAILS */}
      {/* <----------- GWB DETAILS --------------> */}


      {/* <----------- FIGHTSTICK DETAILS --------------> */}
      {/* START MODAL FOR PORTFOLIO DETAILS */}
      <Modal
        isOpen={isOpenFightstick}
        onRequestClose={toggleModalFightstick}
        contentLabel="My dialog"
        className="mymodal"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
      >
        <div className="tokyo_tm_modalbox_news portfolio_tm_modalbox">
          <button className="close-modal" onClick={toggleModalFightstick}>
            <img src="assets/img/svg/cancel.svg" alt="close icon" />
          </button>
          {/* END CLOSE ICON */}
          <div className="box_inner">
            <div className="description_wrap scrollable">
              <div className="image">
                <div
                  className="main"
                  style={{
                    backgroundImage: "url(assets/img/portfolio/tinkering/fightstick/fightstick-splash.png",
                  }}
                ></div>
              </div>
              {/* END IMAGE */}
              <div className="portfolio_main_title">
                <h3>SMT V Fightstick</h3>
                <span>3D Printing</span>
              </div>
              {/* END portfolio_main_title */}
              <div className="main_details">
                <div className="textbox">
                  <p>
                    This is a fully functional fightstick that can connect to PC as a controller for fighting, arcade, and platforming games. 
                    The color scheme, floral decals, and button art were inspired by the uniform design from Shin Megami Tensei V. 
                    The hardware providing the button inputs is the Brook Zero-Pi fighting board.
                  </p>
                  <p>          
                  The most challenging problem was figuring out the best way to draw and put the floral design on the case. 
                  I initially tried to 3D print a thin layer for each flower. 
                  However, I wasn't satisfied with their look and were hard to place on the fightstick's surface. 
                  I tried using a paper cutting machine to cut the decals and the result looked great!
                  </p>
                </div>
                <div className="detailbox">
                  <ul>
                    <li>
                      <span className="first">Modeling Software</span>
                      <span>Fusion 360</span>
                    </li>
                    <li>
                      <span className="first">3D Printer</span>
                      <span>Creality CR-10</span>
                    </li>
                    <li>
                      <span className="first">Series</span>
                      <span>Shin Megami Tensei V</span>
                    </li>
                    <li>
                      <span className="first">Features</span>
                      <span>Hand-drawn decals, custom button art</span>
                    </li>
                  </ul>
                </div>
              </div>
              {/* main_details */}
              <div className="additional_images">
                <ul className="gallery_zoom">
                  <li>
                    <div className="list_inner">
                      <div className="my_image">
                        <img src="img/thumbs/4-2.jpg" alt="thumb" />
                        <div
                          className="main"
                          style={{
                            backgroundImage: "url(assets/img/portfolio/tinkering/fightstick/fightstick-front.png)",
                          }}
                        ></div>
                      </div>
                    </div>
                  </li>
                  {/* END SHOT */}
                  <li>
                    <div className="list_inner">
                      <div className="my_image">
                        <img src="img/thumbs/4-2.jpg" alt="thumb" />
                        <div
                          className="main"
                          style={{
                            backgroundImage: "url(assets/img/portfolio/tinkering/fightstick/detail-1.png",
                          }}
                        ></div>
                      </div>
                    </div>
                  </li>
                  {/* END SHOT */}
                  <li>
                    <div className="list_inner">
                      <div className="my_image">
                        <img src="img/thumbs/4-2.jpg" alt="thumb" />
                        <div
                          className="main"
                          style={{
                            backgroundImage: "url(assets/img/portfolio/tinkering/fightstick/detail-2.png)",
                          }}
                        ></div>
                      </div>
                    </div>
                  </li>
                  {/* END SHOT */}
                  <li>
                    <div className="list_inner">
                      <div className="my_image">
                        <img src="img/thumbs/4-2.jpg" alt="thumb" />
                        <div
                          className="main"
                          style={{
                            backgroundImage: "url(assets/img/portfolio/tinkering/fightstick/fightstick-back.png)",
                          }}
                        ></div>
                      </div>
                    </div>
                  </li>
                  {/* END SHOT */}
                  <li>
                    <div className="list_inner">
                      <div className="my_image">
                        <img src="img/thumbs/4-2.jpg" alt="thumb" />
                        <div
                          className="main"
                          style={{
                            backgroundImage: "url(assets/img/portfolio/tinkering/fightstick/fightstick-top.png)",
                          }}
                        ></div>
                      </div>
                    </div>
                  </li>
                  {/* END SHOT */}
                  <li>
                    <div className="list_inner">
                      <div className="my_image">
                        <img src="img/thumbs/4-2.jpg" alt="thumb" />
                        <div
                          className="main"
                          style={{
                            backgroundImage: "url(assets/img/portfolio/tinkering/fightstick/fightstick-side.png)",
                          }}
                        ></div>
                      </div>
                    </div>
                  </li>
                  {/* END SHOT */}
                </ul>
              </div>
            </div>
          </div>
          {/* END BOX INNER */}
        </div>
        {/* END MODALBOX NEWS */}
      </Modal>
      {/* END MODAL FOR PORTFOLIO DETAILS */}
      {/* <----------- FIGHTSTICK DETAILS --------------> */}


      {/* <----------- LAMP DETAILS --------------> */}
      {/* START MODAL FOR PORTFOLIO DETAILS */}
      <Modal
        isOpen={isOpenLamp}
        onRequestClose={toggleModalLamp}
        contentLabel="My dialog"
        className="mymodal"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
      >
        <div className="tokyo_tm_modalbox_news portfolio_tm_modalbox">
          <button className="close-modal" onClick={toggleModalLamp}>
            <img src="assets/img/svg/cancel.svg" alt="close icon" />
          </button>
          {/* END CLOSE ICON */}
          <div className="box_inner">
            <div className="description_wrap scrollable">
              <div className="image">
                <div
                  className="main"
                  style={{
                    backgroundImage: "url(assets/img/portfolio/tinkering/final_fantasy_lamp/model-splash.png",
                  }}
                ></div>
              </div>
              {/* END IMAGE */}
              <div className="portfolio_main_title">
                <h3>Aethernet Shard Lamp (WIP)</h3>
                <span>3D Printing</span>
              </div>
              {/* END portfolio_main_title */}
              <div className="main_details">
                <div className="textbox">
                  <p>
                    This project is currently a work in progress. 
                  </p>
                  <p>
                    Inspired by the Aethernet Shards in Final Fantasy XIV, I wanted to make a lamp that would rotate and glow similar to the in-game model.
                    I used Fusion360 to model the lamp and export the pieces for 3D printing. To learn how to rotate the top part using a 360° servo, I followed tutorials from Adafruit.
                  </p>
                </div>
                <div className="detailbox">
                  <ul>
                    <li>
                      <span className="first">Modeling Software</span>
                      <span>Fusion360</span>
                    </li>
                    <li>
                      <span className="first">3D Printer</span>
                      <span>Creality CR-10</span>
                    </li>
                    <li>
                      <span className="first">Series</span>
                      <span>Final Fantasy XIV</span>
                    </li>
                    <li>
                      <span className="first">Features</span>
                      <span>Rechargable lamp, rotating top piece</span>
                    </li>
                  </ul>
                </div>
              </div>
              {/* main_details */}
              <div className="additional_images_miku">
                <ul className="gallery_zoom">
                  <li>
                    <div className="list_inner">
                      <div className="miku-halloween">
                        <div
                          className="main"
                          style={{
                            backgroundImage: "url(assets/img/portfolio/tinkering/final_fantasy_lamp/model-front.png",
                          }}
                        ></div>
                      </div>
                    </div>
                  </li>
                  {/* END SHOT */}
                  <li>
                    <div className="list_inner">
                      <div className="miku-halloween">
                        <div
                          className="main"
                          style={{
                            backgroundImage: "url(assets/img/portfolio/tinkering/final_fantasy_lamp/model-side-2.png)",
                          }}
                        ></div>
                      </div>
                    </div>
                  </li>
                  {/* END SHOT */}
                  <li>
                    <div className="list_inner">
                      <div className="miku-halloween">
                        <div
                          className="main"
                          style={{
                            backgroundImage: "url(assets/img/portfolio/tinkering/final_fantasy_lamp/model-bottom.png)",
                          }}
                        ></div>
                      </div>
                    </div>
                  </li>
                  {/* END SHOT */}
                  <li>
                    <div className="list_inner">
                      <div className="miku-halloween">
                        <div
                          className="main"
                          style={{
                            backgroundImage: "url(assets/img/portfolio/tinkering/final_fantasy_lamp/model-details-1.png)",
                          }}
                        ></div>
                      </div>
                    </div>
                  </li>
                  {/* END SHOT */}
                </ul>
              </div>
            </div>
          </div>
          {/* END BOX INNER */}
        </div>
        {/* END MODALBOX NEWS */}
      </Modal>
      {/* END MODAL FOR PORTFOLIO DETAILS */}
      {/* <----------- LAMP DETAILS --------------> */}

{/* <------------------ END MODAL - 3D PRINT------------------> */}



{/* <------------------ MODAL - GK PAINTING ------------------> */}

      {/* <----------- MIKU TRICK DETAILS --------------> */}
      {/* START MODAL FOR PORTFOLIO DETAILS */}
      <Modal
        isOpen={isOpenMikuHalloween}
        onRequestClose={toggleModalMikuHalloween}
        contentLabel="My dialog"
        className="mymodal"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
      >
        <div className="tokyo_tm_modalbox_news portfolio_tm_modalbox">
          <button className="close-modal" onClick={toggleModalMikuHalloween}>
            <img src="assets/img/svg/cancel.svg" alt="close icon" />
          </button>
          {/* END CLOSE ICON */}
          <div className="box_inner">
            <div className="description_wrap scrollable">
              <div className="miku_cover">
                {/* <img src="assets/img/thumbs/4-3.jpg" alt="tumb" /> */}
                <div
                  className="main"
                  style={{
                    backgroundImage: "url(assets/img/portfolio/gk_painting/miku_halloween/miku-cover.png)",
                  }}
                ></div>
              </div>
              {/* END IMAGE */}
              <div className="portfolio_main_title">
                <h3>Trick or Miku</h3>
                <span>GK Painting</span>
              </div>
              {/* END portfolio_main_title */}
              <div className="main_details">
                <div className="textbox">
                  <p>
                  My first completed garage kit! All hand painted using Vallejo and school-grade paints. 
                  Blending the hair colors was tough however I am happy with the result.
                  </p>
                  <p>
                  Base was 3D printed using wood filament. I added basing rocks, bushes, and flowers 
                  to make it look like Miku was flying through the woods while dropping all her candy.
                  </p>
                </div>
                <div className="detailbox">
                  <ul>
                    <li>
                      <span className="first">Tools Used</span>
                      <span>Acrylic paints, 3D printer, model foliage</span>
                    </li>
                    <li>
                      <span className="first">Series</span>
                      <span>Hatsune Miku</span>
                    </li>
                  </ul>
                </div>
              </div>
              {/* main_details */}
              <div className="additional_images_miku">
                <ul className="gallery_zoom">
                  <li>
                    <div className="list_inner">
                      <div className="miku-halloween">
                        <div
                          className="main"
                          style={{
                            backgroundImage: "url(assets/img/portfolio/gk_painting/miku_halloween/miku-side.png)",
                          }}
                        ></div>
                      </div>
                    </div>
                  </li>
                  {/* END SHOT */}
                  <li>
                    <div className="list_inner">
                      <div className="miku-halloween">
                        <div
                          className="main"
                          style={{
                            backgroundImage: "url(assets/img/portfolio/gk_painting/miku_halloween/miku-side-2.png)",
                          }}
                        ></div>
                      </div>
                    </div>
                  </li>
                  {/* END SHOT */}
                  <li>
                    <div className="list_inner">
                      <div className="miku-halloween">
                        <div
                          className="main"
                          style={{
                            backgroundImage: "url(assets/img/portfolio/gk_painting/miku_halloween/miku-front.png)",
                          }}
                        ></div>
                      </div>
                    </div>
                  </li>
                  {/* END SHOT */}
                  <li>
                    <div className="list_inner">
                      <div className="miku-halloween">
                        <div
                          className="main"
                          style={{
                            backgroundImage: "url(assets/img/portfolio/gk_painting/miku_halloween/miku-closeup.png)",
                          }}
                        ></div>
                      </div>
                    </div>
                  </li>
                  {/* END SHOT */}
                  <li>
                    <div className="list_inner">
                      <div className="miku-halloween">
                        <div
                          className="main"
                          style={{
                            backgroundImage: "url(assets/img/portfolio/gk_painting/miku_halloween/miku-details.png)",
                          }}
                        ></div>
                      </div>
                    </div>
                  </li>
                  {/* END SHOT */}
                </ul>
              </div>
            </div>
          </div>
          {/* END BOX INNER */}
        </div>
        {/* END MODALBOX NEWS */}
      </Modal>
      {/* END MODAL FOR PORTFOLIO DETAILS */}
      {/* <----------- MIKU TRICK DETAILS --------------> */}

      {/* <----------- SABER KIMONO DETAILS --------------> */}
      {/* START MODAL FOR PORTFOLIO DETAILS */}
      <Modal
        isOpen={isOpenSaberKimono}
        onRequestClose={toggleModalSaberKimono}
        contentLabel="My dialog"
        className="mymodal"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
      >
        <div className="tokyo_tm_modalbox_news portfolio_tm_modalbox">
          <button className="close-modal" onClick={toggleModalSaberKimono}>
            <img src="assets/img/svg/cancel.svg" alt="close icon" />
          </button>
          {/* END CLOSE ICON */}
          <div className="box_inner">
            <div className="description_wrap scrollable">
              <div className="miku_cover">
                {/* <img src="assets/img/thumbs/4-3.jpg" alt="tumb" /> */}
                <div
                  className="main"
                  style={{
                    backgroundImage: "url(assets/img/portfolio/gk_painting/saber_kimono/saber-cover.png)",
                  }}
                ></div>
              </div>
              {/* END IMAGE */}
              <div className="portfolio_main_title">
                <h3>Saber Kimono Version</h3>
                <span>GK Painting</span>
              </div>
              {/* END portfolio_main_title */}
              <div className="main_details">
                <div className="textbox">
                  <p>
                  This garage kit was challenging to put together however the final model turned out great! The main challenge was parts not fitting together, especially the dress pieces. 
                  I heat transformed the dress pieces then molded them to contour the dress shape. I also used a lot of plastic putty to fill in gaps between parts or make parts fit better. 
                  </p>
                  <p>
                  The parts were painted using airbrushing with acryllic paints. Once painting was finished, sticking the decals on the dress was the final step. It was fun cutting the patterns
                  since the decals had to conform to the dress's curvature. While this kit was tough, the final result was worth the journey!
                  </p>
                </div>
                <div className="detailbox">
                  <ul>
                    <li>
                      <span className="first">Tools Used</span>
                      <span>Acrylic paints, plastic putty</span>
                    </li>
                    <li>
                      <span className="first">Series</span>
                      <span>Fate/Stay Night</span>
                    </li>
                  </ul>
                </div>
              </div>
              {/* main_details */}
              <div className="additional_images_miku">
                <ul className="gallery_zoom">

                  <li>
                    <div className="list_inner">
                      <div className="miku-halloween">
                        <div
                          className="main"
                          style={{
                            backgroundImage: "url(assets/img/portfolio/gk_painting/saber_kimono/saber-side-left.png)",
                          }}
                        ></div>
                      </div>
                    </div>
                  </li>
                  {/* END SHOT */}
                  <li>
                    <div className="list_inner">
                      <div className="miku-halloween">
                        <div
                          className="main"
                          style={{
                            backgroundImage: "url(assets/img/portfolio/gk_painting/saber_kimono/saber-side-right.png)",
                          }}
                        ></div>
                      </div>
                    </div>
                  </li>
                  {/* END SHOT */}
                  <li>
                    <div className="list_inner">
                      <div className="miku-halloween">
                        <div
                          className="main"
                          style={{
                            backgroundImage: "url(assets/img/portfolio/gk_painting/saber_kimono/saber-front.png)",
                          }}
                        ></div>
                      </div>
                    </div>
                  </li>
                  {/* END SHOT */}

                </ul>
              </div>
            </div>
          </div>
          {/* END BOX INNER */}
        </div>
        {/* END MODALBOX NEWS */}
      </Modal>
      {/* END MODAL FOR PORTFOLIO DETAILS */}
      {/* <----------- SABER KIMONO DETAILS --------------> */}

{/* <------------------ END MODAL - GK PAINTING ------------------> */}


    </>
  );
};

export default Portfolio;
